import styled from 'styled-components'

const Styling = styled.div`
width: 100%;
background:var(--white);

    @media only screen and (min-width: 768px) {
    padding-top: 10rem;
    }

    @media only screen and (min-width: 1024px) {
    padding: clamp(6rem,10vw,20rem) 0 0 0;
    }

    .products{
    width:100%;
    position: relative;
    display:grid;
    border-top:1px solid var(--grey_mid);
    grid-gap:2rem;
    grid-template-columns: repeat(1, 1fr);
    padding:4rem 2rem;

        @media only screen and (min-width: 350px) {
        padding:4rem 4rem 0 4rem;
        }
    
        @media only screen and (min-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
        }
        
        @media only screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        margin-top:4rem;
        }

        @media only screen and (min-width: 1024px) {
        padding:4rem 8rem;
        margin-top:8rem;
        }

        @media only screen and (min-width: 1200px) {
        padding:8rem;
        grid-template-columns: repeat(4, 1fr);
        }

        article{
        position: relative;
        }
    
    }

`

export { Styling }