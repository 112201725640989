import React from 'react'
import { graphql } from 'gatsby'
import {Styling} from '../styles/pages/features'
import FeaturesFeaturedSlider from '../components/page-components/FeaturesArchive/FeaturesFeaturedSlider'
import ProductsGridViewMore from '../components/reusable/ProductsGridViewMore'
import Seo from '../components/global/seo'

export default function Features({data}) {
    const posts = data.allPosts.nodes
    const featured = data.featuredPosts.nodes
    

    //  function arraymove(arr, fromIndex, toIndex) {
    //    var element = arr[fromIndex];
    //    arr.splice(fromIndex, 1);
    //    arr.splice(toIndex, 0, element);
    //  }
 
    //  let zeroPostIndex
 
    //  // Find index of post that MENU_ORDER is undefined i.e is 0
    //  posts.map((product, index) => {
    //    if(product.menuOrder === null){
    //      zeroPostIndex = index
    //    }
    //  })
 
    //  // Reorder allProducts array and bring zero post to start of array
    //  arraymove(posts, zeroPostIndex, 0)
 
    return (
        <Styling>
            <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} />
            <FeaturesFeaturedSlider featured={featured} />
            <ProductsGridViewMore products={posts} />
        </Styling>
    )
}


export const query = graphql`
  {
    wpPage(title: {eq: "Features"}) {
      id
      seo {
          metaDesc
          title
      }
    }
    featuredPosts: allWpPost(filter: {categories: {nodes: {elemMatch: {name: {eq: "Featured"}}}}}) {
      nodes {
        id
        title
        slug
        hero: blog_post_fields {
            blogHero {
                introText
                image {
                    localFile{
                        childImageSharp {
                          gatsbyImageData(
                            width: 1500
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                          )
                        }
                    }
                }
            }
        }
        categories {
            nodes {
              name
            }
        }
      }
    }
    allPosts: allWpPost(sort: {fields: menuOrder, order: ASC}, filter: {categories: {nodes: {elemMatch: {name: {ne: "Featured"}}}}}) {
      nodes {
        id
        title
        slug
        menuOrder
        hero: blog_post_fields {
            blogHero {
                introText
                image {
                    localFile{
                        childImageSharp {
                          gatsbyImageData(
                            width: 1500
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                          )
                        }
                    }
                }
            }
        }
        categories {
            nodes {
              name
            }
        }
      }
    }
  }
`