import styled from 'styled-components'

const Styling = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;

    @media only screen and (min-width: 768px) {
    flex-wrap: nowrap;
    }

    .swiper-1{
    width: 100%;

        @media only screen and (min-width: 768px) {
        width: 45%;
        padding-left: 8rem;
        }

        .gatsby-image-wrapper{
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;

            @media only screen and (min-width: 550px) {
            padding-bottom: 56%;
            }

            @media only screen and (min-width: 768px) {
            height:100%;
            position:absolute !important;
            }
        }

    }

    .swiper-2{
    width: 100%;

        @media only screen and (min-width: 768px) {
        width: 55%;
        }

        .swiper-slide{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
        opacity: 0 !important;
        padding:4rem;
            
            @media only screen and (min-width: 1024px) {
            padding:10rem;
            }

            &.swiper-slide-active{
            opacity: 1 !important;
            z-index: 100 !important;
            }

            .label{
            margin-bottom:4rem;
            width: 100%;
            position: relative;
            text-transform:uppercase;
            ${({theme}) => theme.tomato('bold')}  
            font-size:clamp(1.4rem,0.8vw, 2rem);
            }

            .title{
            margin-bottom:4rem;
            width: 100%;
            position: relative;
            ${({theme}) => theme.tomato('regular')}  
            letter-spacing:-0.09em;
            text-transform:uppercase;
            font-size:clamp(3rem,3vw, 9rem);
            }
            
            .excerpt{
            margin-bottom:4rem;
            width: 100%;
            position: relative;
            ${({theme}) => theme.telegraf('regular')}  
            font-size:clamp(1.4rem,1.2vw, 3rem);
            line-height:clamp(1.4rem*1.25,1.2vw*1.25, 3rem*1.25);
            }

            .buttons{
            display: flex;
            align-items: center;
                /* button{
                margin-right:1rem;
                }

                a{
                margin-left:2rem ;
                } */

                .arrow{
                border:1px solid var(--black);
                width:50px;
                height: 50px;
                border-radius:100%;
                margin-right:1rem;
                ${({theme}) => theme.easing('0.5')} 

                    &.next{
                    margin-right:4rem;
                    }

                    svg{
                    width:30px;
                    height:30px;
                    ${({theme}) => theme.easing('0.5')}  
                    top:2px;
                    position: relative;
                    }

                    &:hover{
                    background:var(--black);
                        svg{
                        fill:var(--white);
                        }
                    }
                }
            }

        }
    }
`

export { Styling }