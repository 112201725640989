import React, {useState, useRef} from 'react'
import { Styling } from './styled.js'
import {Link} from 'gatsby'
import {BsArrowRightShort, BsArrowLeftShort}from 'react-icons/bs'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination"

import "swiper/css/navigation"



import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
// import Swiper core and required modules
import SwiperCore, {
    Pagination,Navigation,EffectFade, Controller, Autoplay
  } from 'swiper/core';

  SwiperCore.use([Pagination, Navigation, EffectFade, Controller, Autoplay]);


export default function FeaturesFeaturedSlider({featured}) {

    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);
    const prevRef = useRef()
    const nextRef = useRef()
    
    return (
        <Styling>
            <Swiper
            spaceBetween={typeof window !== 'undefined' ? window.innerWidth / 100 * 1 : ''}
            slidesPerView={1}
            className="swiper-1"
            loop={true} 
            onSwiper={setFirstSwiper} controller={{ control: secondSwiper }}
            navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
            }}
            onInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
            autoplay={{
                "delay": 2500,
                "disableOnInteraction": true
              }}
            >
            {
                featured.map((post, index) => {

                    const theImage = getImage(post?.hero?.blogHero?.image?.localFile);
                    return(
                        <SwiperSlide>
                            <GatsbyImage image={theImage} className="image" alt={'test'} />
                        </SwiperSlide>
                    )
                })
            }
            </Swiper>

            <Swiper
            slidesPerView={1}
            loop={true} 
            className="swiper-2"
            effect="fade" 
            onSwiper={setSecondSwiper} 
            controller={{ control: firstSwiper }}
            >
            {
                featured.map((post, index) => {
                    return(
                        <SwiperSlide>
                            <div className="label">Featured</div>
                            <h2 className="title">{post.title}</h2>
                            <div className="excerpt">{post.hero.blogHero.introText}</div>
                            <div className="buttons">
                                <button ref={prevRef} onClick={(swiper) => {secondSwiper.slideToLoop(secondSwiper.realIndex-1)}} className="arrow prev" aria-label="Previous"><BsArrowLeftShort /></button>
                                <button ref={nextRef} onClick={(swiper) => {secondSwiper.slideToLoop(secondSwiper.realIndex+1)}} className="arrow next" aria-label="Next"><BsArrowRightShort /></button>
                                <Link to={`/features/${post.slug}`} className="capsule_button white">Read more</Link>
                            </div>
                        </SwiperSlide>
                    )
                })
            }
            </Swiper>
   
        </Styling>
    )
}
